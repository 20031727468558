const SPACE_DATA = {
		"u6988791":  [{space_js: "//ccddbd.hanganan.cn/production/bw/vey/yx/resource/e/openjs/f.js", space_src: "baidujs", space_type: "inters", space_id: "u6988791"}],
		"u6988792":  [{space_js: "//ccddbd.hanganan.cn/production/c_x/wf_z/openjs/zyfm.js", space_src: "baidujs", space_type: "native", space_id: "u6988792"}],
		"u6988793":  [{space_js: "//ccddbd.hanganan.cn/source/d-y/static/x/openjs/gaa-zgq.js", space_src: "baidujs", space_type: "native", space_id: "u6988793"}],
		"u6988794":  [{space_js: "//ccddbd.hanganan.cn/common/e/zyhb/b/static/ahv.js", space_src: "baidujs", space_type: "native", space_id: "u6988794"}],
		"u6988795":  [{space_js: "//ccddbd.hanganan.cn/site/fa/static/z/openjs/ic_c/resource/bix.js", space_src: "baidujs", space_type: "native", space_id: "u6988795"}],
		"u6988796":  [{space_js: "//ccddbd.hanganan.cn/production/gb/source/a-j/common/ddcj-a.js", space_src: "baidujs", space_type: "inters", space_id: "u6988796"}],
		"u6988797":  [{space_js: "//ccddbd.hanganan.cn/production/h/resource/c/bke_edk_d.js", space_src: "baidujs", space_type: "native", space_id: "u6988797"}],
		"u6988798":  [{space_js: "//ccddbd.hanganan.cn/source/idclf/common/f/static/e-l-f.js", space_src: "baidujs", space_type: "native", space_id: "u6988798"}],
		"u6988799":  [{space_js: "//ccddbd.hanganan.cn/production/je-dmggf-m/common/m.js", space_src: "baidujs", space_type: "native", space_id: "u6988799"}],
		"u6988800":  [{space_js: "//ccddbd.hanganan.cn/site/rmlu/production/o/source/oo/ss.js", space_src: "baidujs", space_type: "native", space_id: "u6988800"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6988791"],
	Home_banner:  SPACE_DATA["u6988792"],
	Main_native:  SPACE_DATA["u6988793"],
	Classroom_native:  SPACE_DATA["u6988794"],
	Wikipedia_cat_native:  SPACE_DATA["u6988793"],
	Wikipedia_dog_native:  SPACE_DATA["u6988794"],
	Wikipedia_other_native:  SPACE_DATA["u6988795"],
	ConstellationList_banner:  SPACE_DATA["u6988792"],
	ConstellationList_native:  SPACE_DATA["u6988793"],
	ArticleDetail_inter:  SPACE_DATA["u6988791"],
	ArticleDetail_banner:  SPACE_DATA["u6988792"],
	ArticleDetail_native:  SPACE_DATA["u6988793"],
	PetDetail_inter:  SPACE_DATA["u6988791"],
	PetDetail_banner:  SPACE_DATA["u6988792"],
	PetDetail_native:  SPACE_DATA["u6988793"],
	Mood_dialog:  SPACE_DATA["u6988794"],
}
export const ALL_SPACE_MAP = {
    H5_HRCW_VO_7 : {
		Home_inter:  SPACE_DATA["u6988791"],
		Home_banner:  SPACE_DATA["u6988792"],
		Main_native:  SPACE_DATA["u6988793"],
		Classroom_native:  SPACE_DATA["u6988794"],
		Wikipedia_cat_native:  SPACE_DATA["u6988793"],
		Wikipedia_dog_native:  SPACE_DATA["u6988794"],
		Wikipedia_other_native:  SPACE_DATA["u6988795"],
		ConstellationList_banner:  SPACE_DATA["u6988792"],
		ConstellationList_native:  SPACE_DATA["u6988793"],
		ArticleDetail_inter:  SPACE_DATA["u6988791"],
		ArticleDetail_banner:  SPACE_DATA["u6988792"],
		ArticleDetail_native:  SPACE_DATA["u6988793"],
		PetDetail_inter:  SPACE_DATA["u6988791"],
		PetDetail_banner:  SPACE_DATA["u6988792"],
		PetDetail_native:  SPACE_DATA["u6988793"],
		Mood_dialog:  SPACE_DATA["u6988794"],
    },
    H5_HRCW_VO_8 : {
		Home_inter:  SPACE_DATA["u6988796"],
		Home_banner:  SPACE_DATA["u6988797"],
		Main_native:  SPACE_DATA["u6988798"],
		Classroom_native:  SPACE_DATA["u6988799"],
		Wikipedia_cat_native:  SPACE_DATA["u6988798"],
		Wikipedia_dog_native:  SPACE_DATA["u6988799"],
		Wikipedia_other_native:  SPACE_DATA["u6988800"],
		ConstellationList_banner:  SPACE_DATA["u6988797"],
		ConstellationList_native:  SPACE_DATA["u6988798"],
		ArticleDetail_inter:  SPACE_DATA["u6988796"],
		ArticleDetail_banner:  SPACE_DATA["u6988797"],
		ArticleDetail_native:  SPACE_DATA["u6988798"],
		PetDetail_inter:  SPACE_DATA["u6988796"],
		PetDetail_banner:  SPACE_DATA["u6988797"],
		PetDetail_native:  SPACE_DATA["u6988798"],
		Mood_dialog:  SPACE_DATA["u6988800"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6988791"],
		Home_banner:  SPACE_DATA["u6988792"],
		Main_native:  SPACE_DATA["u6988793"],
		Classroom_native:  SPACE_DATA["u6988794"],
		Wikipedia_cat_native:  SPACE_DATA["u6988793"],
		Wikipedia_dog_native:  SPACE_DATA["u6988794"],
		Wikipedia_other_native:  SPACE_DATA["u6988795"],
		ConstellationList_banner:  SPACE_DATA["u6988792"],
		ConstellationList_native:  SPACE_DATA["u6988793"],
		ArticleDetail_inter:  SPACE_DATA["u6988791"],
		ArticleDetail_banner:  SPACE_DATA["u6988792"],
		ArticleDetail_native:  SPACE_DATA["u6988793"],
		PetDetail_inter:  SPACE_DATA["u6988791"],
		PetDetail_banner:  SPACE_DATA["u6988792"],
		PetDetail_native:  SPACE_DATA["u6988793"],
		Mood_dialog:  SPACE_DATA["u6988794"],
    },
};
